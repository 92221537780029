/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
    @-ms-viewport {
        width: device-width;
    } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow:none;
}

b, strong {
  font-weight: 700 !important;
}

h1,
h2,
h3,
h4,
p,
small,
a {
    margin: 0;
}

h1 {
    font-size: 38px;
    font-weight: 700;
}

h2 {
    font-size: 28px;
    font-weight: 700;
}

h3 {
    font-size: 20px;
    font-weight: 700;
}

h4 {
    font-size: 16px;
    font-weight: 700;
}

p {
    font-size: 16px;
    font-weight: 400;
}

small {
    font-size: 14px;
}

a {
    font-size: 16px;
    text-decoration: none;
}

label {
    font-size: 12px;
    font-weight: 600;
}

.container.small-container {
  max-width: 582px!important
}



@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/grid-framework";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/spacing";